export default function getQueryParam(queryName) {
  if (typeof window === "undefined") {
    return;
  }

  const url = new URL(document.location.href);
  if (url.searchParams.has(queryName)) return url.searchParams.get(queryName);

  try {
    const storage = window.sessionStorage;
    if (storage) {
      return storage.getItem(queryName);
    }
  } catch (e) {
    console.warn(e);
  }

  return;
}
