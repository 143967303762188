export default function hasQueryParam(queryName, hrefToCheck = "") {
  if (typeof window === "undefined") {
    return;
  }

  // if optional hrefToCheck got passed, check hrefToCheck for query param, else check document location
  const url = hrefToCheck
    ? new URL(hrefToCheck)
    : new URL(document.location.href);
  return url.searchParams.has(queryName);
}
