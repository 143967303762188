import { useState, useEffect } from "react";
import fetchData from "../fetchData";

export default function useFetch(url, options = {}, condition = true) {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    async function fetchAndSetResponse() {
      const response = await fetchData(url, options);
      setResponse(response);
    }

    if (condition) {
      fetchAndSetResponse();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return response;
}
