export default function numToEuroString(number) {

  if (typeof number !== "number") {
    console.error("Funct numToEuroString: please provide a number:" + number);
  }

  return number.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  });

}
